<template>
<div class="wrap">
    <div class="mg-auto" style="width: 1250px;">
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> B2C 지출 등록</h2>

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">지출내용 <span class="red">*</span></th>
                                <td class="left">
                                    <input v-model="expText" type="text" class="ml-20 w-200px">
                                </td>
                                <th>지출금액 <span class="red">*</span></th>
                                <td class="left">
                                    <input v-model="expPrice" type="text" class="ml-20 w-200px"> 원
                                </td>
                            </tr>
                            <tr>
                                <th>지출날짜 <span class="red">*</span></th>
                                <td class="left" colspan="3">
                                    <crm-datepicker class="ml-20" v-model="expensesDate" :parentDate="expensesDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                </td>
                            </tr>
                            <tr>
                                <th>사용자</th>
                                <td class="left">
                                    <input v-model="expensesName" type="text" class="ml-20 w-200px">
                                </td>
                                <th>센터선택</th>
                                <td class="left">
                                    <select v-model="idxCrmCenter" class="ml-20 w-100px">
                                        <option value="-1">센터선택</option>
                                        <option value="0">본원센터</option>
                                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 111px;">메모</th>
                                <td class="left" colspan="3">
                                    <textarea v-model="expensesMemo" type="text" class="ml-20 w-98per h-100px" id="introduce" name="introduce" value="" />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle mr-10 pointer" @click="submit()">등록</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
export default {
    data: () => ({
        centerList: [],
        idxCrmCenter: -1,
        expText: '',
        expensesName: '',
        expensesCompanyName: '',
        expPrice: '',
        expensesDate: '',
        centerName: '',
        expensesMemo: '',
        expensesType: 'B2C',
        btnDisabled:false, //등록버튼 활성화/비활성화 여부
    }),

    mounted() {
        this.expensesDate = this.formatDate(new Date())
        this.getCenterListAll()
    },

    methods: {
        // 등록
        submit() {

            if (!EmptyCheck(this.expText, '지출내용을')) {
                return false
            }

            if (!EmptyCheck(this.expPrice + '', '지출금액을')) {
                return false
            }

            if (!EmptyCheck(this.formatDate(this.expensesDate), '지출일자를')) {
                return false
            }

            if (this.idxCrmCenter !== -1) {
                if (this.idxCrmCenter === "0") {
                    this.centerName = '본원센터'
                } else {
                    for (var center of this.centerList) {
                        if (this.idxCrmCenter === center.idx) {
                            this.centerName = center.name
                        }
                    }
                }
            }

            let vo = {
                expText: this.expText,
                expPrice: this.expPrice,
                expensesDate: this.formatDate(this.expensesDate),
                expensesName: this.expensesName,
                idxCrmCenter: this.idxCrmCenter,
                centerName: this.centerName,
                expensesMemo: this.expensesMemo,
                expensesCompanyName: this.expensesCompanyName
            }

            if(this.btnDisabled){
                alert('저장중입니다. 잠시만 기다려주세요.');
                return false;
            }

            this.btnDisabled = true;
            this.axios.post('/api/v1/user/expenses', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert(res.data.result)
                        this.$router.push('/origin/sales_manage_expend_b2c')
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.btnDisabled = false;
                })
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        }
    }
}
</script>
